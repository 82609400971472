import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import EmpleadosService from "../services/EmpleadosService";

Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [
    createPersistedState({
      storage: window.sessionStorage,
    }),
  ],
  state: {
    user: null,
  },
  mutations: {
    SET_USER_DATA(state, userData) {
      state.user = userData;
      state.user.permission = userData.apps.includes("Empleados_App_");
      localStorage.setItem("user", JSON.stringify(userData));
      EmpleadosService.setToken(userData.token);
    },
    CLEAR_USER_DATA(state) {
      localStorage.removeItem("user");
      state.user = null;
      location.reload();
    },
  },
  actions: {
    login({ commit }, user_credentials) {
      return EmpleadosService.login(user_credentials).then(({ data }) => {
        commit("SET_USER_DATA", data);
      });
    },
    logout({ commit }) {
      commit("CLEAR_USER_DATA");
    },
  },
  getters: {
    getToken(state) {
      if (state.user) return state.user.token;
      else return null;
    },
    loggedIn(state) {
      return !!state.user;
    },
  },
});
